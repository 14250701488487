import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import MoreVertItems from "../MoreVertItems";
import CloseIcon from "@mui/icons-material/Close";
import Warning from "../Modal/Warning";
import { Link } from "react-router-dom";
const ShopCard = (props) => {
  const { imgUrl, Shop_Name, Karta_Name, Mobile } = props;
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  // const imgUrl = "";
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          display: "grid",
          width: "300px",
          height: "auto",
          my: 4,
          mx: 2,
          boxShadow: "10px 10px 5px 5px #e1e1e1",
          backgroundColor: "white",
          backgroundImage: `url('/circle.png'), url('/circle.png')`,
          backgroundSize: "300px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "170px -85px, -170px 355px",
          border: "2px #e1e1e1",
        }}>
        <div style={{ zIndex: 2, position: "absolute" }}>
          <IconButton onClick={handleClick} title="Delete">
            <CloseIcon sx={{ color: "red" }} />
          </IconButton>
        </div>

        <Link
          to={`verifiedShopsList/shopDetails/${props.id}`}
          style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              marginY: "20px",
              justifyContent: "center",
              width: "auto",
              height: "200px",
            }}>
            {imgUrl ? (
              <Box
                align="center"
                sx={{
                  backgroundImage: `url("${imgUrl}")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  width: "200px",
                  height: "auto",
                }}></Box>
            ) : (
              <Box
                align="center"
                sx={{
                  backgroundImage: `url("/no-image.jpg")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  width: "200px",
                  height: "auto",
                }}></Box>
            )}
          </Box>
        </Link>
        <Box textAlign="center" zIndex="2">
          <Typography
            sx={{
              margin: "1px",
              paddingX: "20px",
              fontWeight: "700",
              fontSize: "23px",
              fontFamily: "math",
              lineHeight: "26px",
            }}>
            <b>Shop Name: </b> {Shop_Name}
          </Typography>
          <Typography
            color="text.secondary"
            component="div"
            lineHeight="20px"
            fontFamily="math"
            fontSize="18px"
            sx={{ paddingX: "20px", marginX: "12px", mt: 1 }}>
            <b>Owner Name: </b>
            {Karta_Name}
          </Typography>
          <Typography
            color="text.secondary"
            component="div"
            fontFamily="math"
            lineHeight="20px"
            fontSize="15px"
            sx={{ paddingX: "20px", margin: "12px", mt: 1 }}>
            <b>Mobile: </b> {Mobile}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" title="More" paddingTop="40px">
          <MoreVertItems id={props.id} />
        </Box>
      </Card>

      <Warning
        open={open}
        onClose={handleClose}
        id={props.id}
        component="Shop"
      />
    </>
  );
};
export default ShopCard;
