export const setAuthToken = (token) => ({
  type: "SET_AUTH_TOKEN",
  payload: token,
});

export const setLoading = (value) => {
  return {
    type: "SET_LOADING",
    payload: value,
  };
};

export const setUserId = (value) => {
  return {
    type: "SET_USER_ID",
    payload: value,
  };
};
