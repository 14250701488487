// CategoryApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";
import { toast } from "react-toastify";

const baseURL = backendApiUrl;

const ChildCategoryApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the token in the Authorization header for all requests
ChildCategoryApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your category-related API endpoints
export const getProductChildCategories = async () => {
  try {
    const response = await ChildCategoryApi.get("/productChildCategory");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildCategoriesBySubCategoryId = async (id) => {
  try {
    const response = await ChildCategoryApi.get(
      `/productChildCategory/${id}/subcategory`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createChildCategory = async (subcategoryData) => {
  try {
    const response = await ChildCategoryApi.post(
      "/productChildCategory",
      subcategoryData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteChildCategory = async (id, userId) => {
  try {
    const response = await ChildCategoryApi.delete(
      `/productChildCategory/${id}`,
      {
        data: { userId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editChildCategoryById = async (id, data) => {
  try {
    const response = await ChildCategoryApi.put(
      `/productChildCategory/${id}`,
      data
    );
    toast.success("Child Category Updated Successfully");
    return response.data;
  } catch (error) {
    toast.error("Child Category Update Failed", error);
    throw error;
  }
};

// Add other category-related API endpoints as needed

export default ChildCategoryApi;
