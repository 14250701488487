import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setAuthToken,
  clearAuthToken,
} from "../../../store/reducers/authSlice";
import "./LogIn.css";
import { toast } from "react-toastify";
import { setLoading, setUserId } from "../../../store/actions/actions";
import { Users } from "../../../constants";

const theme = createTheme({
  palette: {
    primary: { main: "#1565c0" },
    secondary: { main: "#f9003b" },
  },
});

const SignIn = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [creds, setCreds] = React.useState({ mobile: "", password: "" });
  let isAuthUser = false;
  const handleChange = (e) => {
    const value = e.target.value;
    setCreds({ ...creds, [e.target.name]: value });
  };

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    handleLoading(true);
    for (const user of Users) {
      if (user.userName == creds.mobile && user.password == creds.password) {
        isAuthUser = true;
        break;
      }
    }
    if (isAuthUser) {
      handleLoading(false);
      toast.success("Logged in successfully!");
      sessionStorage.setItem("isAuthenticated", "true");
      dispatch(setAuthToken(true));
      dispatch(setUserId(creds.mobile));
      navigate("/admin/*");
    } else {
      handleLoading(false);
      toast.error("Incorrect Credentials!");
    }
  };

  const handleLogout = () => {
    dispatch(clearAuthToken());
    toast.success("Logged out successfully!");
  };

  useEffect(() => {
    let isReloading = false;

    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("isReloading", "true");
      setTimeout(() => {
        sessionStorage.removeItem("isReloading");
      }, 10);
    };

    const handleLoad = () => {
      if (sessionStorage.getItem("isReloading")) {
        isReloading = true;
      }
    };

    const handleUnload = () => {
      if (!isReloading) {
        handleLogout();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("unload", handleUnload);
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className="background">
        <Typography
          variant="h3"
          textAlign="center"
          color="white"
          sx={{ pt: 6, pl: 4 }}>
          Welcome To <span style={{ color: "#f9003b" }}>Litchies</span> Admin
        </Typography>
        <div className="wrapper">
          <div className="SideImage"></div>
          <div className="LoginForm">
            <h2 className="h3">LOGIN</h2>
            <div className="Profile"></div>
            <div className="group">
              <TextField
                type="text"
                size="30"
                id="mobile"
                className="input"
                label="Mobile"
                variant="standard"
                color="secondary"
                name="mobile"
                onChange={handleChange}
                required
              />
            </div>
            <div className="group">
              <TextField
                type="password"
                className="input"
                id="password"
                name="password"
                label="Password"
                variant="standard"
                color="secondary"
                onChange={handleChange}
                required
              />
            </div>
            <Button
              type="submit"
              className="btn"
              color="secondary"
              variant="contained"
              onClick={handleLogin}>
              <span>LOGIN</span>
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SignIn;
