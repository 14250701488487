import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"; // Replace with CloseIcon
import Carousel from "../carousel/Caraousal"; // Fix typo in the import
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { deleteProduct } from "../../../API/ProductApi";
import { toast } from "react-toastify";
import { setLoading } from "../../../store/actions/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ProductCard = (props) => {
  const { imgUrl, Product_Name, Product_Desc, Price, id, mrp } = props;
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userId.userId);
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const erase = async () => {
    handleLoading(true);
    deleteProduct(id, userId)
      .then((res) => {
        if (res.status == false) {
          handleLoading(false);
          toast.error(res.message);
        } else {
          handleLoading(false);
          toast.success(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  function truncateDescription(description, limit) {
    if (description.length <= limit || description.length <= 30) {
      return description;
    } else {
      return description.substring(0, limit) + "...";
    }
  }

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <Card
      sx={{
        width: "320px",
        height: "540px",
        m: 3,
        p: 0,
        boxShadow: "10px 10px 5px 5px #e1e1e1",
        border: "2px #e1e1e1",
        position: "relative", // To position delete button
      }}>
      <IconButton
        onClick={() => erase()} // Call onDelete function with the product ID
        sx={{
          position: "absolute",
          top: 6,
          right: 6,
          zIndex: 1,
          color: "red", // Set the color to red
        }}>
        <CloseIcon />
      </IconButton>

      <Box align="center" height="330px" m="1vh">
        {typeof imgUrl !== "string" && imgUrl.length > 0 ? (
          <Carousel images={imgUrl} shopId={props.shopId} id={props.id} />
        ) : typeof imgUrl === "string" ? (
          <Link
            to={`/admin/verifiedShopsList/showProducts/${props.shopId}/${props.id}`}
            style={{ textDecoration: "none" }}>
            <img src={imgUrl} alt="No Image" width="300px" height="300px" />
          </Link>
        ) : (
          <Link
            to={`/admin/verifiedShopsList/showProducts/${props.shopId}/${props.id}`}
            style={{ textDecoration: "none" }}>
            <img src="/no-image.jpg" alt="No Image" />
          </Link>
        )}
      </Box>
      <Box align="center" p="1vh" display="grid">
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "16px",
            fontFamily: "inter",
            lineHeight: "30px",
            color: "grey",
          }}>
          {truncateDescription(Product_Name, 20)}
        </Typography>
        <Box maxHeight="8vh" overflow="hidden">
          <Typography>
            <div
              dangerouslySetInnerHTML={createMarkup(
                truncateDescription(Product_Desc, 60)
              )}
            />
          </Typography>
        </Box>
        <Typography
          color="text.secondary"
          component="div"
          fontSize="12px"
          sx={{ mt: 1 }}>
          {Price > 0 ? (
            <>
              <b>Price:</b>
              <strike>{mrp}</strike>
              <b> {Price}</b>
            </>
          ) : (
            <b>Price: {Price}</b>
          )}
        </Typography>
      </Box>
    </Card>
  );
};

export default ProductCard;
