import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, TextField, Modal, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChildCategoryList from "./ChildCategory";
import Warning from "../Modal/Warning";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import {
  getProductSubCategories,
  editSubCategoriesById,
} from "../../../API/SubCategoryApi";

const SubCategoryList = ({ id }) => {
  const [category, setCategory] = useState([]);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [sid, setSId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (catId) => {
    setSId(catId._id);
    setOpen(true);
  };

  const fetchSubCategories = async () => {
    try {
      const data = await getProductSubCategories();
      if (Array.isArray(data.data)) {
        const filteredData = data.data.filter((d) => d.categoryId === id);
        setCategory(filteredData);
      } else {
        console.error("Fetch category error: Data is not an array");
      }
    } catch (error) {
      console.error("Fetch category error:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, [id]);

  const handleEditClick = (cat) => {
    setEditCategoryData(cat);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditCategoryData(null);
  };

  const handleInputChange = (e) => {
    setEditCategoryData({
      ...editCategoryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = async () => {
    if (editCategoryData) {
      try {
        const formData = new FormData();
        formData.append("_id", editCategoryData._id);
        formData.append("name", editCategoryData.name);

        await editSubCategoriesById(editCategoryData._id, formData);

        fetchSubCategories();
        handleModalClose();
      } catch (error) {
        console.error("Update category error:", error);
      }
    }
  };

  return (
    <>
      {category.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {category.map((cat) => (
                  <TableRow key={cat.name}>
                    <TableCell component="td" scope="row">
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ flexGrow: 1 }}>
                        <TreeItem nodeId="1" label={cat.name}>
                          <TreeItem
                            nodeId="2"
                            label={<ChildCategoryList id={cat._id} />}
                          />
                        </TreeItem>
                      </TreeView>
                    </TableCell>
                    <TableCell sx={{ display: "flex", padding: 0 }}>
                      <Button
                        sx={{ margin: "5px" }}
                        variant="contained"
                        onClick={() => handleEditClick(cat)}>
                        Edit
                      </Button>
                      <Button
                        onClick={() => handleOpen(cat)}
                        variant="text"
                        style={{ color: "red" }}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Warning
        open={open}
        onClose={handleClose}
        id={sid}
        component="Sub Category"
      />
      {/* Edit Subcategory Modal */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="edit-subcategory-title"
        aria-describedby="edit-subcategory-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
          <Typography id="edit-subcategory-title" variant="h6" component="h2">
            Edit Subcategory
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Subcategory Name"
            name="name"
            value={editCategoryData ? editCategoryData.name : ""}
            onChange={handleInputChange}
          />

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleModalClose}
              sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SubCategoryList;
