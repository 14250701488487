import { Button } from "@mui/material";
import React from "react";
import { deleteShop } from "../../../API/ShopApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { useSelector } from "react-redux";
const DeleteShop = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userId.userId);
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const erase = async () => {
    handleLoading(true);
    deleteShop(props.id, userId)
      .then((response) => {
        if (response.status == true) {
          handleLoading(false);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        } else {
          handleLoading(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <>
      <Button onClick={erase} color="error">
        Delete Shop
      </Button>
    </>
  );
};

export default DeleteShop;
